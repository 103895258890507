import React, {Component} from "react"
import {Link} from "gatsby"
import styled from 'styled-components'

const HomeButtonWrapper = styled.div`
    text-align: center;
    position: absolute;
    bottom: 7rem;
    width: 100%;

    @media (min-width: 768px) {
        bottom: 5rem;
    }
`


const ButtonBkGround = styled.div`
    padding: 1rem 3rem;
    margin: 0;
    text-decoration: none;
    background: linear-gradient(to right, rgba(226,248,252,1) 0%,rgba(226,248,252,1) 25%,rgba(138,236,245,1) 100%); 
    background-color: #8AECF5;
    color: #3D81BA;
    display: inline-block;
    font-size: 1.25rem;

    a {
        color: #3D81BA;
        font-family: 'Proxima Nova W05 Bold';
        text-decoration: none;
    }

`


class HomeButton extends Component {
    onClick = () => {
        window.did.launchNewsletters('frozen')
    }
    render() {
        return (
            <HomeButtonWrapper>
               <ButtonBkGround>
                  <Link to="/">Home</Link>
               </ButtonBkGround>
            </HomeButtonWrapper>
        )
    }
}

export default HomeButton