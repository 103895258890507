import React, {Component} from "react"
import Layout from "components/Layout/Layout"
import styled from 'styled-components'
import HomeButton from "components/Button/HomeButton"
import SEO from "utils/Seo"

const ImageWrapper = styled.div`
    height: 100vh;
    position: relative;
    width: 100%;
    background: url("/images/404-mobile-bg-min.jpg") no-repeat;
    background-size: cover;


     @media (min-width: 768px) {
        background: url("/images/404-desktop-bg-min.jpg") no-repeat;
        background-size: cover;
    }

`

class NotFoundPage extends Component {
	render() {
		return (
			<Layout>
				<SEO slug="404" title="404: Not found"/>
				<ImageWrapper>
					<div className="error-text">
						<h1>404</h1>
						<h5>Oops... snow page here</h5>
						<p>We couldn’t find what you’re looking for. If you entered the URL <br/> yourself please check
							it was correct, or click below for the homepage</p>
					</div>
					<HomeButton/>
				</ImageWrapper>
			</Layout>
		)
	}
}

export default NotFoundPage
